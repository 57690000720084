import React, { ReactNode } from "react"

import './FindUs.css'

type FindUsProps = {
    children?: ReactNode
}

export function FindUs(props: FindUsProps) {
    return (
        <div className="map-flex">
            <div>
                <p> 7285 W 132nd St, Suite 240 <br /> Overland Park, KS 66213 <br /> <b>+01 785.550.5274</b></p>
            </div>
            <iframe title={"Map to Cambrian"}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3105.5557496827223!2d-94.67265578464992!3d38.888404779572056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0c1dc0eb95b89%3A0x201047336ddb119c!2s7285%20W%20132nd%20St%20%23240%2C%20Overland%20Park%2C%20KS%2066213!5e0!3m2!1sen!2sus!4v1571819072606!5m2!1sen!2sus"
                className="map" allowFullScreen={false} />
        </div>
    )
}
import React, { useCallback, useEffect, useRef } from "react"
import Observer from '@researchgate/react-intersection-observer'
import './VideoPlayer.css'

export enum PlayType {
    normal,
    auto,
    inView,
    hover,
    click
}

type VideoPlayerProps = {
    className?: string
    imagePlaceholder?: string
    play?: PlayType
    delay?: number

    primaryPath: string
    secondaryPath?: string
}

export function VideoPlayer(props: VideoPlayerProps) {

    const playType = props.play ? props.play : PlayType.inView

    const getVideoFormat = useCallback((filePath: string) => {
        return `video/${filePath.split('.').pop()}`
    }, [])

    const videoElement = useRef<HTMLVideoElement>(null)
    const wasPlaying = useRef(false)

    const handleIntersection = useCallback((event: IntersectionObserverEntry) => {
        if (!videoElement.current) return

        if (!event.isIntersecting) {
            videoElement.current.pause()
        } else if (wasPlaying.current || playType === PlayType.inView) {
            videoElement.current.play()
        }
    }, [videoElement, playType])

    const onClick = useCallback(() => {
        if (videoElement.current && playType === PlayType.click) {
            videoElement.current.play()
        }
    }, [videoElement, playType])

    const onMouseOver = useCallback(() => {
        if (videoElement.current && playType === PlayType.hover) {
            videoElement.current.play()
        }
    }, [videoElement, playType])

    const onPlaying = useCallback(() => {
        wasPlaying.current = true
    }, [wasPlaying])

    useEffect(() => {
        if (videoElement.current && !wasPlaying.current) {
            videoElement.current.load()
        }
    }, [videoElement, wasPlaying])

    return (
        <Observer onChange={(event: IntersectionObserverEntry) => handleIntersection(event)}>
            <video ref={videoElement} className={props.className} playsInline loop muted
                onClick={onClick} onPlaying={onPlaying} onMouseOver={onMouseOver}
                autoPlay={playType === PlayType.auto || playType === PlayType.inView}
                poster={props.imagePlaceholder}>
                <source src={props.primaryPath} type={getVideoFormat(props.primaryPath)} />
                {props.secondaryPath && <source src={props.secondaryPath} type={getVideoFormat(props.secondaryPath)} />}
            </video>
        </Observer>
    )
}

import * as React from "react"
import './Privacy.css'

export default function PrivacyPolicy(props: any) {
    return (
        <div className={"privacy"}>

            <iframe title={"privacy-policy"} src={"https://app.termly.io/document/privacy-policy/b35230e3-c9fa-4b35-a9a5-b54c40a0e1df"}>

            </iframe>

        </div>
    )
}

import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import cssVars from 'css-vars-ponyfill'

import React, { useReducer, useEffect, useState } from "react"
import * as ReactDOM from "react-dom"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { HomePage } from "./pages/HomePage"

import { SiteContext, createEmptyState, siteStateReducer } from "./data/SiteContext"
import Visualizer from "./pages/Visualizer"
import {BrowserProperties, WebClientInfo} from "react-client-info"
import { NavigationMenu } from "./components/Navigation"
import { Footer } from "./components/Footer"
import PrivacyPolicy from "./pages/Privacy";
import DesignUp from "./pages/DesignUp";

const objectFitImages = require('object-fit-images')

function App() {
    const initialSiteState = createEmptyState()
    const [siteState, dispatchSiteState] = useReducer(siteStateReducer, initialSiteState)

    // Url load states
    const [,setBrowserProperties] = useState<BrowserProperties>({})

    //component mounted:
    useEffect(() => {
        cssVars()
        objectFitImages()

        return () => {
            //unmount
        }
    }, [])

    return (
        <Router>
            <Route
                render={({ location }) => {

                    return (
                        <SiteContext.Provider value={{ state: siteState, dispatch: dispatchSiteState }}>

                            <WebClientInfo onClientStateChanged={setBrowserProperties} />
                            <NavigationMenu />

                            <div className={"main"}>
                                <Switch location={location}>
                                    <Route exact path="/" component={HomePage} />
                                    <Route exact path="/visualizer" component={Visualizer} />
                                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                                    <Route exact path="/support/design-up" component={DesignUp} />
                                </Switch>
                            </div>

                            <Footer />
                        </SiteContext.Provider>
                    )
                }}
            />
        </Router>
    )
}

ReactDOM.render(
    <App />,
    document.getElementById("root")
)
import React from "react"
import * as licenseData from '../data/license-partners.json'
import './LicenseLogos.css'
import { MediaPaths } from "../data/SiteContext"

export function LicenseLogos() {
    return (
        <div className={"license-logos"}>
            {licenseData.profiles.map(profile =>
                <div key={profile.name} className={"license-logo-container"}>
                    <img src={`${MediaPaths.LicenseLogos}/${profile.image}`} alt={profile.title} />
                </div>
            )}
        </div>
    )
}
import React, { createRef, ReactNode, useCallback, useRef } from "react"

import './ContactUs.css'

export const isValidEmail = (value: string): string => {
    return value.search(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/
    ) && value
        ? "Field must be in a valid email format"
        : ""
}

export const isRequired = (value: string): string =>
    value === undefined ||
        value === null ||
        value === ""
        ? "Field must be populated"
        : ""

type ValidatedField = {
    element: React.RefObject<HTMLElement>
    validators: ((value: string) => string)[]
}

type ContactUsProps = {
    children?: ReactNode
}

export function ContactUs(props: ContactUsProps) {

    const emailFnameRef = createRef<HTMLInputElement>()
    const emailLnameRef = createRef<HTMLInputElement>()
    const emailFromRef = createRef<HTMLInputElement>()
    const emailSubjectRef = createRef<HTMLInputElement>()
    const emailBodyRef = createRef<HTMLTextAreaElement>()
    const emailSendButtonRef = createRef<HTMLInputElement>()

    const contactUsForm = useRef<HTMLFormElement>(null)
    const confirmMessage = useRef<HTMLDivElement>(null)

    const toggleConfirm = useCallback((show: boolean) => {
        if (confirmMessage.current) {
            if (!show && emailSendButtonRef.current) {
                emailSendButtonRef.current.disabled = true
                if (contactUsForm.current) {
                    contactUsForm.current.reset()
                }
            }
            confirmMessage.current.style.display = show ? "block" : "none"
        }
    }, [emailSendButtonRef, confirmMessage, contactUsForm])

    const fields: ValidatedField[] = [
        { element: emailFnameRef, validators: [isRequired] },
        { element: emailLnameRef, validators: [isRequired] },
        { element: emailFromRef, validators: [isRequired, isValidEmail] },
        { element: emailSubjectRef, validators: [isRequired] },
        { element: emailBodyRef, validators: [isRequired] }
    ]

    const validate = useCallback(() => {
        let isValid = true
        for (const field of fields) {
            let fieldValue = null
            let fieldElement = field.element.current!

            if (fieldElement as HTMLInputElement) {
                fieldValue = (field.element.current as HTMLInputElement).value
            } else if (fieldElement as HTMLTextAreaElement) {
                fieldValue = (field.element.current as HTMLTextAreaElement).value
            }

            if (fieldValue === null) continue

            let fieldErrors = []
            for (const validator of field.validators) {
                const result = validator(fieldValue)
                if (result) {
                    if (isValid) {
                        fieldElement.focus()
                    }
                    fieldElement.style.border = "3px solid red"
                    fieldErrors.push(result)
                    isValid = false
                }
            }
            if (fieldErrors.length) {
                console.log(fieldErrors[0])
            } else {
                fieldElement.style.border = ""
            }
        }
        return isValid
    }, [fields])

    const handleSubmit = useCallback((event) => {
        const formData = contactUsForm.current

        if (!formData || !validate()) return

        const request = new XMLHttpRequest()

        request.open('POST', "contact-us", true)

        request.onload = function () { // request successful
            toggleConfirm(true)
        }

        request.onerror = function () {
            // request failed
        }

        request.send(new FormData(formData)) // create FormData from form that triggered event
        event.preventDefault()

    }, [toggleConfirm, validate])

    return (
        <div className="form-flex">
            <form ref={contactUsForm} encType={"url-encoded"}>
                <div className="name-row">
                    <input ref={emailFnameRef} type="text" id="fname" className="input name" name="fname"
                        placeholder="First Name" required />
                    <input ref={emailLnameRef} type="text" id="lname" className="input name" name="lname"
                        placeholder="Last Name" required />
                </div>

                <input type="text" id="company" className="input" name="company"
                    placeholder="Company Name" />

                <input ref={emailFromRef} type="text" id="email" className="input" name="email"
                    placeholder="Email" required />

                <input ref={emailSubjectRef} type="text" id="subject" className="input" name="subject"
                    placeholder="Subject" />

                <textarea ref={emailBodyRef} id="message" className="input tall" name="message" placeholder="Message..." required />
                <br />

                <input type="button" ref={emailSendButtonRef} className="button-send" name="submit" value="Send Message" onClick={handleSubmit} />
            </form>

            <div ref={confirmMessage} className="confirm" id="confirm" onClick={() => { toggleConfirm(false) }}>
                <div className="confirm-box">
                    <div className="confirm-text">Message Sent! We will respond to you shortly.
                    </div>
                    <input type="button" className="button-send" name="submit" value="OK" onClick={() => { toggleConfirm(false) }} />
                </div>
            </div>
        </div>
    )
}
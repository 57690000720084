import * as React from "react"
import './HomePage.css'
import { MediaPaths } from "../data/SiteContext"
import { SiteSection, SiteSectionLR } from "../components/SiteSection"
import { ContactUs } from "../components/ContactUs"
import { FindUs } from "../components/FindUs"
import { VideoPlayer } from "../components/VideoPlayer"
import { CompanyProfile } from "../components/CompanyProfile"
import { LicenseLogos } from "../components/LicenseLogos"

export function HomePage(props: any) {

    return (
        <div className="home-page">
            <div className={"home-page-video-container"}>
                <VideoPlayer className={"home-page-video"} imagePlaceholder={`${MediaPaths.Video}/cambrian-main.jpg`}
                    primaryPath={`${MediaPaths.Video}/cambrian-main.mp4`} secondaryPath={`${MediaPaths.Video}/cambrian-main.webm`} />
                <div className="home-page-content">
                    <h1>IMAGINE. VISUALIZE. TRANSFORM.</h1>
                    <h2>Your Space. Your Design. Endless Possibilities.</h2>
                </div>
            </div>

            <SiteSectionLR left dataAnchor={"visualizer"} leftSubTitle={"AI Powered Home Visualization"} leftContent={
                <div>
                    Imagine being able to visualize your space with new
                    flooring, paint and furniture, using nothing more than a touch of your fingertips. Imagine not
                    guessing but knowing how that color and floor will look in your home. We offer realistic and
                    accurate representations of your space, transformed. We take the guesswork out of choosing a
                    paint color or flooring type by showing how it will actually look in your home.

                    We offer this as a simple add-on to any existing website, with your own brand and products.
                    <div className="download-flex">
                        <a target={"_blank"} href={"https://web-demo-staging.cambrianar.com/"}>See Working Example</a>
                    </div>

                </div>
            } rightContent={
                <div onClick={()=>window.open("https://web-demo-staging.cambrianar.com/")}>
                    <VideoPlayer className={"visualizer-video"} delay={3000}
                                 imagePlaceholder={`${MediaPaths.Video}/visualizer.png`} primaryPath={`${MediaPaths.Video}/visualizer.mp4`} />
                    <p className="fullpage-subtitle">Realistic AR from a Photo</p>
                </div>
            } />

            <SiteSectionLR left dataAnchor={"home-harmony"} leftSubTitle={"Home Harmony"} leftContent={
                <div>
                    Imagine being able to visualize your space with new
                    paint &amp; flooring using nothing more than a touch of your fingertips. Imagine not
                    guessing but knowing how that color and floor will look in your home. We offer realistic and
                    accurate representations of your space, transformed. We take the guesswork out of choosing a
                    paint color or flooring type by showing how it will actually look in your home.
                    <div className="download-flex">
                        <p className="download-text">Download Home Harmony</p>
                    </div>

                    <div className="app-button-grid">
                        <a className="app play" target="_blank" rel="noopener noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.cambriantech.paintharmonylite">
                            <img src={`${MediaPaths.Shared}/google-play.png`} alt={"Home Harmony - Google Play"} />
                        </a>
                        <a className="app ios" target="_blank" rel="noopener noreferrer"
                            href="https://itunes.apple.com/us/app/home-harmony/id1069490038?mt=8">
                            <img src={`${MediaPaths.Shared}/apple-app-store.png`} alt={"Home Harmony - iTunes"} />
                        </a>
                    </div>
                </div>
            } rightContent={
                <div>
                    <VideoPlayer className={"paint-before-after-video"} delay={3000}
                        imagePlaceholder={`${MediaPaths.Video}/paint-before-after.png`} primaryPath={`${MediaPaths.Video}/paint-before-after.mp4`} />
                    <p className="fullpage-subtitle">The Real Time Home Designer</p>
                </div>
            } />

            <SiteSection dataAnchor={"technology"}>
                <p className={"fullpage-title"}>Who We Are</p>
                <div>
                    <p className="fullpage-text">We are engineers, scientists, and artificial intelligence experts who are committed to
                        providing the next generation of practical augmented and mixed reality for home design, real-estate, builders and architects.
                        With ten years experience in computer vision, deep learning and marketing, we provide solutions that are not only realistic,
                        but also very easy to use. We are working to create a complete home design experience and have many design firsts in the
                        field of augmented reality. We have licensed our solution to many Fortune 500 companies globally, and have assisted with both agencies
                        or our own custom development. Our employees have a rich experience in computer intelligence solutions, augmented reality, and custom approaches
                        to solve the most difficult of problems. We are engineering the future of shopping and design of the home
                        and are well on our way to complete home transformation.
                    </p>
                </div>
                <CompanyProfile />
            </SiteSection>

            <SiteSection dataAnchor={"technology"}>
                <p className={"fullpage-title"}>Technology</p>

                <VideoPlayer className={"tech-video"} delay={3000} imagePlaceholder={`${MediaPaths.Video}/tech.jpg`}
                    primaryPath={`${MediaPaths.Video}/tech.mp4`} />
                <div>
                    <p className="fullpage-text">Cambrian uses computer vision and machine learning to make our augmented
                        reality applications easy to use and as realistic as possible to deliver the best experience to the
                        user. Our Visualizer and AR platform uses the most sophisticated and advanced technology available,
                        including our own patent pending algorithms, to allow users to visualize their space transformed
                        with their own mobile device.</p>
                </div>
            </SiteSection>

            <SiteSection dataAnchor={"flooring-web"}>
                <p className={"fullpage-title"}>Website Visualization</p>

                <img src={`${MediaPaths.Home}/web-flooring.png`} className={"web-flooring-image"} alt={"Web Flooring"} />
                <div>
                    <p className="fullpage-text">Within any website or within a mobile app, we provide a solution that using just an uploaded photo,
                        automatically replaces the flooring underneath your feet. Using state-of-the-art deep learning algorithms, the system
                        removes the existing floor or painted wall and replaces it with any other material, such as rugs, tile, carpet, or hardwoods.
                        The existing lighting and shadows are preserved, making it seem like the material has been replaced in the real world.
                        The system is also capable of placing furniture within any uploaded image because it understands precisely where the floors and walls are.
                    </p>
                </div>

            </SiteSection>

            <SiteSectionLR left dataAnchor={"ar-flooring"} leftSubTitle={"Live AR Flooring"} leftContent={
                <div className={"row-flex"}>
                    As the first company to debut a live augmented reality solution for flooring, we can provide realistic and walkable flooring
                    while also measuring the area under your feet. This solution uses the latest in our own custom and high speed deep learning algorithms
                    to display material underneath and around objects. Like our web solution, it only requires a repeating image and we can do the rest.
                    We offer services for easy integration and can assist as necessary.
                </div>
            } rightContent={
                <div>
                    <VideoPlayer className={"tech-video"} imagePlaceholder={`${MediaPaths.Video}/flooring.jpg`}
                        primaryPath={`${MediaPaths.Video}/flooring.mp4`} />
                </div>
            } />

            <SiteSectionLR right dataAnchor={"painting"} leftContent={
                <div className={"row-flex"}>
                    <div>
                        <div>
                            <VideoPlayer className={"tech-video"} imagePlaceholder={`${MediaPaths.Video}/tile.jpg`}
                                primaryPath={`${MediaPaths.Video}/tile.mp4`} />
                        </div>
                    </div>
                    <div>
                        <div>
                            <VideoPlayer className={"tech-video"} imagePlaceholder={`${MediaPaths.Video}/paint.jpg`}
                                primaryPath={`${MediaPaths.Video}/paint.mp4`} />
                        </div>
                    </div>
                </div>
            } rightSubTitle={"Painting & Walls"} rightContent={
                <div>
                    Our custom algorithms use principles from deep learning and
                    computer vision to identify the likely boundaries of where the user wants paint based on a single
                    touch alone. Our smart paint brushes can adjust to edges from a general definition of
                    where to paint, so a rough touch or swipe is enough to generate detailed results.
                    Cambrian was the first company to publish a live augmented reality solution for paint in the
                    mobile app store.
                </div>
            } />

            <SiteSectionLR left dataAnchor={"realism"} leftSubTitle={"Realistic Representation"} leftContent={
                <div className={"row-flex"}>
                    Cambrian's technology preserves shadows, retains highlights,
                    and recreates the lighting in the room to give the user an accurate realistic augmented
                    representation of their space. Using machine learning, we are able to train the programs
                    to realistically render a scene.
                </div>
            } rightContent={
                <div>
                    <img alt={"Realism"} className="tech-image" src={`${MediaPaths.Home}/realistic-representation.jpg`} />
                </div>
            } />

            <SiteSection dataAnchor={"about-us"}>
                <p className="fullpage-title">Work With Us</p>
                <p className="fullpage-text">
                    If you are a builder, a painter, an architect, an engineer, or an industry leader in home improvement, reach out to us.
                    If you are an advertising agency that has a client needing to solve a hard problem, we can solve it.
                    We are able to work with existing solutions or catalogs, incorporate web technologies such as Adobe Scene7,
                    and transform everything into a modern and realistic form for the common user.
                    Our systems are a drop in to most websites or apps and designed for even novice users.
                    We have experience integrating with large and scalable solutions, capable of managing thousands of users at a time,
                    all while running the most advanced infrastructure. Contact us below or set up a meeting sometime.
                    We would be glad to talk.

                </p>
                <LicenseLogos />
            </SiteSection>

            <SiteSectionLR dataAnchor={"contact-us"} className={"contact-us"} leftContent={
                <ContactUs />
            } rightSubTitle={"Find Us"} rightContent={
                <FindUs />
            } />

        </div>
    )
}

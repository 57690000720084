import React from "react"
import * as employeeData from '../data/employee-profiles.json'
import { EmployeProfile } from "./EmployeeProfile"
import './CompanyProfile.css'

export function CompanyProfile() {
    return (
        <div className={"company-profile"}>
            {employeeData.profiles.map(profile =>
                <EmployeProfile key={profile.name} name={profile.name}
                    title={profile.title}
                    imageSrc={profile.image}
                    summary={profile.summary}
                    url={profile.url} />
            )}
        </div>
    )
}
import React, { ReactNode } from "react"

import './Footer.css'
import { MediaPaths } from "../data/SiteContext"

type FooterProps = {
    children?: ReactNode
}

export function Footer(props: FooterProps) {
    return (
        <div>
            <div>
                <div className="footer">
                    <div className="follow-flex">
                        <div className="copyright">
                            <p className="copyright-text">Copyright © {new Date().getFullYear().toString()}, Cambrian</p>
                        </div>
                        <div className="follow-menu">
                            <a href="https://www.facebook.com/cambriantech/" target="_blank" rel="noopener noreferrer" className="follow-item first"
                                style={{ backgroundImage: `url('${MediaPaths.Shared}/icon-facebook.png')` }} >&nbsp;</a>
                            <a href="https://www.pinterest.com/cambriantech/" target="_blank" rel="noopener noreferrer" className="follow-item"
                                style={{ backgroundImage: `url('${MediaPaths.Shared}/ic-Pinterest.png')` }} >&nbsp;</a>
                            <a href="https://twitter.com/cambriantech" target="_blank" rel="noopener noreferrer" className="follow-item last"
                                style={{ backgroundImage: `url('${MediaPaths.Shared}/ic-Twitter.png')` }} >&nbsp;</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


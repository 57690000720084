import React, { useCallback, useRef } from "react"
import { MediaPaths } from "../data/SiteContext"
import './EmployeeProfile.css'

type EmployeProfileProps = {
    name: string
    title: string
    imageSrc: string
    summary: string
    url: string
}

export function EmployeProfile(props: EmployeProfileProps) {
    const employeeSummaryElement = useRef<HTMLDivElement>(null)

    const mouseOver = useCallback((event: any) => {
        if (employeeSummaryElement.current) {
            employeeSummaryElement.current.style.display = "block"
        }
    }, [employeeSummaryElement])

    const mouseOut = useCallback((event: any) => {
        if (employeeSummaryElement.current) {
            employeeSummaryElement.current.style.display = "none"
        }
    }, [employeeSummaryElement])

    const onClick = useCallback((url: string) => {
        window.open(url)
    }, [])

    return (
        <div className={"employee-profile"} onMouseOver={mouseOver} onMouseOut={mouseOut} onClick={() => onClick(props.url)} >
            <div className={"profile-photo-container"}>
                <img className={"employee-image"} src={`${MediaPaths.Employees}/${props.imageSrc}`} alt={`${props.name} profile`} />
                <div ref={employeeSummaryElement} className={"employee-summary"}>
                    {props.summary}
                </div>
            </div>
            <div className={"name"}>{props.name}</div>
            <div className={"title"}>{props.title}</div>
        </div>
    )
}
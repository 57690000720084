import React, { useCallback } from "react"
import { MediaPaths } from "../data/SiteContext"
import animateScrollTo from 'animated-scroll-to'

import './Navigation.css'

type NavigationMenuProps = {

}

export function NavigationMenu(props: NavigationMenuProps) {
    const navigateTo = useCallback((query: string) => {
        const element = document.getElementById(query)
        if (element) {
            animateScrollTo(element)
        }
    }, [])

    return (
        <div className="nav">
            <img className={"logo"} src={`${MediaPaths.Shared}/logo.png`} alt={"logo"} />
            <div className={"nav-flex"} >
                <button className="nav-item" onClick={() => { navigateTo("home-harmony") }}>Product</button>
                <button className="nav-item" onClick={() => { navigateTo("technology") }}>Technology</button>
                <button className="nav-item" onClick={() => { navigateTo("documentation") }}>Documentation</button>
                <button className="nav-item" onClick={() => { navigateTo("about-us") }}>About Us</button>
                <button className="nav-item" onClick={() => { navigateTo("contact-us") }}>Contact</button>
            </div>
        </div>
    )
}

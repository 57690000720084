import React, { ReactNode } from "react"
import './SiteSection.css'

type SiteSectionProps = {
    dataAnchor: string
    className?: string
    children?: ReactNode
}

export function SiteSection(props: SiteSectionProps) {
    return (
        <div className={`site-section ${props.className ? props.className : ""}`} id={props.dataAnchor} data-anchor={props.dataAnchor}>
            <div className={'site-section-container'}>
                {props.children}
            </div>
        </div>
    )
}

type SiteSectionLRProps = {
    dataAnchor: string
    className?: string
    left?: boolean
    leftSubTitle?: string
    leftContent?: ReactNode
    right?: boolean
    rightSubTitle?: string
    rightContent?: ReactNode
}

export function SiteSectionLR(props: SiteSectionLRProps) {
    return (
        <SiteSection className={props.className} dataAnchor={props.dataAnchor}>
            <div className={`row-flex ${props.right || props.left ? (props.right ? "content-right" : "content-left") : ""}`}>
                <div className="flex-left">
                    {props.leftSubTitle && <p className="fullpage-subtitle">{props.leftSubTitle}</p>}
                    <div className="fullpage-text">
                        {props.leftContent}
                    </div>
                </div>
                <div className="flex-right">
                    {props.rightSubTitle && <p className="fullpage-subtitle">{props.rightSubTitle}</p>}
                    <div className="fullpage-text">
                        {props.rightContent}
                    </div>
                </div>
            </div>
        </SiteSection>
    )
}

